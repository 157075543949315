export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }

  trackUser(user) {
    console.log('Analytics track user:', user?.id);
  }

  trackCheckout(event, data) {
    console.log('Analytics track checkout:', event, data);
  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window.gtag) {
      window.setTimeout(() => {
        window.gtag('event', 'page_view', {
          page_path: canonicalPath,
        });
      }, 300);
    }
  }

  // Track user properties in a privacy-compliant way
  trackUser(user) {
    if (!window.gtag || !user?.id) {
      return;
    }

    // Set user ID for cross-device tracking using the internal ID
    gtag('config', window.GA_MEASUREMENT_ID, {
      user_id: user.id
    });

    // Get profile completion percentage
    const getProfileCompletionStatus = (profile) => {
      if (!profile) return 0;
      
      const steps = {
        has_avatar: !!profile.publicData?.avatar,
        has_bio: !!profile.bio,
        has_location: !!profile.publicData?.location,
        email_verified: !!user.attributes?.emailVerified
      };
      
      const completed = Object.values(steps).filter(Boolean).length;
      const total = Object.keys(steps).length;
      return Math.round((completed / total) * 100);
    };

    // Calculate account age in days
    const getAccountAge = (createdAt) => {
      if (!createdAt) return 0;
      const created = new Date(createdAt);
      const now = new Date();
      const diffTime = Math.abs(now - created);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    // Set user properties that don't contain PII
    gtag('set', 'user_properties', {
      user_type: user.attributes?.profile?.publicData?.userType || 'customer',
      account_age_days: getAccountAge(user.attributes?.createdAt),
      profile_completion: getProfileCompletionStatus(user.attributes?.profile),
      is_email_verified: !!user.attributes?.emailVerified,
      has_listings: user.attributes?.profile?.publicData?.hasListings || false,
      has_stripe_account: !!user.attributes?.stripeConnected,
      preferred_language: user.attributes?.profile?.preferredLanguage,
      country: user.attributes?.profile?.publicData?.location?.country
    });

    // Track user login event
    gtag('event', 'user_login', {
      method: 'email', // or could be 'facebook', 'google', etc.
      user_type: user.attributes?.profile?.publicData?.userType || 'customer'
    });
  }

  // Track checkout events
  trackCheckout(event, data) {
    if (!window.gtag) {
      return;
    }

    const safeData = {
      ...data,
      timestamp: new Date().toISOString(),
    };

    // Remove any potential PII
    delete safeData.email;
    delete safeData.name;
    delete safeData.phone;
    delete safeData.address;
    delete safeData.inquiryMessage; // Don't track the actual message content

    switch (event) {
      case 'begin_checkout':
        gtag('event', 'begin_checkout', {
          listing_id: safeData.listingId,
          listing_type: safeData.listingType,
          process_name: safeData.processName,
          currency: safeData.currency,
          value: safeData.price
        });
        break;

      case 'inquiry_submitted':
        gtag('event', 'generate_lead', {
          listing_id: safeData.listingId,
          listing_type: safeData.listingType,
          process_name: safeData.processName,
          currency: safeData.currency,
          value: safeData.price
        });
        break;

      case 'inquiry_error':
        gtag('event', 'inquiry_error', {
          listing_id: safeData.listingId,
          error_type: safeData.errorType,
          error_message: safeData.errorMessage
        });
        break;
    }
  }

  // Track user engagement events
  trackUserEngagement(eventName, eventData = {}) {
    if (!window.gtag) {
      return;
    }

    // Ensure no PII is included in the event data
    const safeEventData = {
      ...eventData,
      timestamp: new Date().toISOString(),
    };

    // Remove any potential PII fields
    delete safeEventData.email;
    delete safeEventData.name;
    delete safeEventData.phone;
    delete safeEventData.address;

    gtag('event', eventName, safeEventData);
  }
}

// PostHog analytics handler
export class PostHogHandler {
  constructor() {
    // PostHog is loaded in index.js via PostHogProvider
    this.isLoaded = typeof window !== 'undefined' && window.posthog;
  }

  trackPageView(canonicalPath, previousPath) {
    if (this.isLoaded && previousPath) {
      // Only manually capture page views for in-app navigation
      // Initial page view is captured automatically by PostHog
      window.posthog.capture('$pageview', {
        $current_url: canonicalPath,
        previous_path: previousPath,
      });
    }
  }

  trackUser(user) {
    if (!this.isLoaded || !user?.id) {
      return;
    }

    // Identify user with their ID
    window.posthog.identify(user.id, {
      email: user.attributes?.email,
      name: user.attributes?.profile?.displayName,
      user_type: user.attributes?.profile?.publicData?.userType || 'customer',
      email_verified: !!user.attributes?.emailVerified,
      has_stripe_account: !!user.attributes?.stripeConnected,
      preferred_language: user.attributes?.profile?.preferredLanguage,
    });

    // Calculate profile completion percentage
    const getProfileCompletionStatus = (profile) => {
      if (!profile) return 0;
      
      const steps = {
        has_avatar: !!profile.publicData?.avatar,
        has_bio: !!profile.bio,
        has_location: !!profile.publicData?.location,
        email_verified: !!user.attributes?.emailVerified
      };
      
      const completed = Object.values(steps).filter(Boolean).length;
      const total = Object.keys(steps).length;
      return Math.round((completed / total) * 100);
    };

    // Set user properties
    window.posthog.people.set({
      profile_completion: getProfileCompletionStatus(user.attributes?.profile),
      account_created_at: user.attributes?.createdAt,
      country: user.attributes?.profile?.publicData?.location?.country,
      has_listings: user.attributes?.profile?.publicData?.hasListings || false,
    });
  }

  trackCheckout(event, data) {
    if (!this.isLoaded) {
      return;
    }

    const safeData = {
      ...data,
      timestamp: new Date().toISOString(),
    };

    // Remove any potential PII that we don't want to track
    delete safeData.inquiryMessage;

    switch (event) {
      case 'begin_checkout':
        window.posthog.capture('begin_checkout', {
          listing_id: safeData.listingId,
          listing_type: safeData.listingType,
          process_name: safeData.processName,
          currency: safeData.currency,
          value: safeData.price
        });
        break;

      case 'inquiry_submitted':
        window.posthog.capture('inquiry_submitted', {
          listing_id: safeData.listingId,
          listing_type: safeData.listingType,
          process_name: safeData.processName,
          currency: safeData.currency,
          value: safeData.price
        });
        break;

      case 'inquiry_error':
        window.posthog.capture('inquiry_error', {
          listing_id: safeData.listingId,
          error_type: safeData.errorType,
          error_message: safeData.errorMessage
        });
        break;
    }
  }
}
