import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import useWishlist from '../../hooks/useWishlist';

import css from './IconHeart.module.css';

/**
 * Heart icon for wishlist functionality.
 * Shows either an outline heart (not favorited) or filled heart (favorited).
 * Uses the useWishlist hook for its functionality.
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className - add more style rules in addition to components own root class
 * @param {string?} props.rootClassName - overwrite components own root class
 * @param {string} props.listingId - the id of the listing to toggle in wishlist
 * @returns {JSX.Element} heart icon
 */
const IconHeart = props => {
  const { className, rootClassName, listingId } = props;
  const { isInWishlist, isProcessing, toggleWishlist } = useWishlist(listingId);
  
  const classes = classNames(rootClassName || css.root, className, {
    [css.filled]: isInWishlist,
    [css.outline]: !isInWishlist,
  });

  return (
    <button 
      className={classes} 
      onClick={toggleWishlist}
      disabled={isProcessing}
      title={
        isInWishlist 
          ? <FormattedMessage id="IconHeart.saved" defaultMessage="Remove from wishlist" />
          : <FormattedMessage id="IconHeart.save" defaultMessage="Add to wishlist" />
      }
      aria-label={
        isInWishlist 
          ? <FormattedMessage id="IconHeart.saved" defaultMessage="Remove from wishlist" />
          : <FormattedMessage id="IconHeart.save" defaultMessage="Add to wishlist" />
      }
    >
      <svg 
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg"
        className={css.svg}
      >
        {isInWishlist ? (
          // Filled heart
          <path 
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          />
        ) : (
          // Outline heart
          <path 
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          />
        )}
      </svg>
    </button>
  );
};

IconHeart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHeart.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  listingId: PropTypes.string.isRequired,
};

export default IconHeart; 