import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isInWishlist } from '../util/wishlist/wishlist';
import { toggleInWishlist } from '../ducks/user.duck';

/**
 * Custom hook to handle wishlist functionality
 * 
 * @param {string} listingId - The ID of the listing to toggle in wishlist
 * @returns {Object} - Wishlist related states and handlers
 */
export const useWishlist = (listingId) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [optimisticState, setOptimisticState] = useState(null);
  
  // Get currentUser from Redux store
  const currentUser = useSelector(state => state.user.currentUser);
  const dispatch = useDispatch();
  
  // Determine if the listing is in the wishlist
  const isFilled = isInWishlist(currentUser, listingId);
  
  // Use optimistic state if available, otherwise use the computed value
  const isInUserWishlist = optimisticState !== null ? optimisticState : isFilled;
  
  // Reset optimistic state when the actual isFilled value changes (e.g. after a server response)
  useEffect(() => {
    setOptimisticState(null);
  }, [isFilled]);

  const handleToggleWishlist = (e) => {
    // Prevent click from propagating to parent elements (like ListingCard link)
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    // Prevent multiple clicks while processing
    if (isProcessing) {
      return;
    }
    
    // Set processing state and optimistic update
    setIsProcessing(true);
    setOptimisticState(!isInUserWishlist);
    
    // If user is not logged in, handle redirection and localStorage storage
    if (!currentUser) {
      // Save current URL to localStorage for redirect back after login
      if (typeof window !== 'undefined') {
        const currentUrl = window.location.pathname + window.location.search;
        localStorage.setItem('wishlistRedirectUrl', currentUrl);
        
        // Save listing ID to localStorage for adding to wishlist after login
        const tempWishlist = JSON.parse(localStorage.getItem('tempWishlist') || '[]');
        if (!tempWishlist.includes(listingId)) {
          tempWishlist.push(listingId);
          localStorage.setItem('tempWishlist', JSON.stringify(tempWishlist));
        }
        
        // Redirect to login page
        window.location.href = '/login?wishlist=true';
      }
      
      setIsProcessing(false);
      return Promise.resolve(); // Return a resolved promise for non-logged in users
    }
    
    // Dispatch the toggleInWishlist action directly
    return dispatch(toggleInWishlist(listingId))
      .then(() => {
        // Success - keep the optimistic state
        setIsProcessing(false);
      })
      .catch(() => {
        // Error - revert the optimistic state
        setOptimisticState(null);
        setIsProcessing(false);
      });
  };

  return {
    isInWishlist: isInUserWishlist,
    isProcessing,
    toggleWishlist: handleToggleWishlist,
  };
};

export default useWishlist; 