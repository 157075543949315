import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ResponsiveImage } from '../../components';
import css from './ProtectedResponsiveImage.module.css';

/**
 * ProtectedResponsiveImage component wraps the ResponsiveImage component
 * and adds protection features to prevent easy downloading of images.
 * This is particularly useful for protecting artwork in listing images.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.className - Optional CSS class for the component
 * @param {Object} props.image - Image object from the API
 * @param {Array<string>} props.variants - Image variants to use
 * @param {string} props.alt - Alt text for the image
 * @param {string} props.sizes - Sizes attribute for responsive images
 * @param {boolean} props.showWatermark - Whether to show watermark (default: true)
 * @param {string} props.watermarkText - Text for the watermark (default: "Protected Artwork")
 * @returns {JSX.Element} - Protected responsive image component
 */
const ProtectedResponsiveImage = props => {
  const {
    className,
    image,
    variants,
    alt,
    sizes,
    showWatermark = true,
    watermarkText = 'Protected Artwork',
    ...rest
  } = props;

  const containerClasses = classNames(css.container, className);

  return (
    <div className={containerClasses} onContextMenu={e => e.preventDefault()}>
      <ResponsiveImage
        rootClassName={css.image}
        image={image}
        variants={variants}
        alt={alt}
        sizes={sizes}
        {...rest}
      />
      <div className={css.protection} />
    </div>
  );
};

ProtectedResponsiveImage.defaultProps = {
  className: null,
  sizes: null,
  showWatermark: true,
  watermarkText: 'Protected Artwork',
};

ProtectedResponsiveImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object.isRequired,
  variants: PropTypes.arrayOf(PropTypes.string).isRequired,
  alt: PropTypes.string.isRequired,
  sizes: PropTypes.string,
  showWatermark: PropTypes.bool,
  watermarkText: PropTypes.string,
};

export default ProtectedResponsiveImage; 