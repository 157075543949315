import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import useWishlist from '../../hooks/useWishlist';

import css from './WishlistButton.module.css';

/**
 * An alternative wishlist button with a different style.
 * Uses the same wishlist functionality via the useWishlist hook.
 *
 * @component
 * @param {Object} props
 * @param {string?} props.className - add more style rules in addition to components own root class
 * @param {string?} props.rootClassName - overwrite components own root class
 * @param {string} props.listingId - the id of the listing to toggle in wishlist
 * @param {boolean} props.showText - whether to show text label alongside the icon
 * @returns {JSX.Element} wishlist button
 */
const WishlistButton = props => {
  const { className, rootClassName, listingId, showText } = props;
  const { isInWishlist, isProcessing, toggleWishlist } = useWishlist(listingId);
  
  const classes = classNames(rootClassName || css.root, className, {
    [css.saved]: isInWishlist,
    [css.notSaved]: !isInWishlist
  });

  const iconClasses = classNames(css.icon, {
    [css.iconFilled]: isInWishlist,
    [css.iconOutline]: !isInWishlist,
  });

  const buttonText = isInWishlist
    ? <FormattedMessage id="WishlistButton.saved" defaultMessage="Added to wishlist" />
    : <FormattedMessage id="WishlistButton.save" defaultMessage="Add to wishlist" />;

  return (
    <button 
      className={classes} 
      onClick={toggleWishlist}
      disabled={isProcessing}
      aria-label={
        isInWishlist 
          ? <FormattedMessage id="WishlistButton.savedAriaLabel" defaultMessage="Remove from wishlist" />
          : <FormattedMessage id="WishlistButton.saveAriaLabel" defaultMessage="Add to wishlist" />
      }
    >
      <div className={css.iconWrapper}>
        <svg 
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg"
          className={iconClasses}
        >
          <path 
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          />
        </svg>
      </div>
      {showText && (
        <span className={css.text}>{buttonText}</span>
      )}
    </button>
  );
};

WishlistButton.defaultProps = {
  className: null,
  rootClassName: null,
  showText: false,
};

WishlistButton.propTypes = {
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  listingId: PropTypes.string.isRequired,
  showText: PropTypes.bool,
};

export default WishlistButton; 