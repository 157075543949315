import { createResourceLocatorString } from '../routes';

/**
 * Check if a listing is in the user's wishlist
 * 
 * @param {Object} currentUser - The current user object
 * @param {String} listingId - The ID of the listing to check
 * @returns {Boolean} - Whether the listing is in the user's wishlist
 */
export const isInWishlist = (currentUser, listingId) => {
  
  if (!currentUser) {
    // For non-logged-in users, check localStorage
    if (typeof window !== 'undefined') {
      const tempWishlist = JSON.parse(localStorage.getItem('tempWishlist') || '[]');
      return tempWishlist.includes(listingId);
    }
    return false;
  }
  
  // Check if the user object has the expected structure
  if (!currentUser.attributes || !currentUser.attributes.profile) {
    console.error('User profile is not properly structured');
    return false;
  }
  
  const { attributes } = currentUser;
  
  const { profile } = attributes;
  
  const { privateData } = profile;
  
  // If privateData is missing, return false
  if (!privateData) {
    return false;
  }
  
  const { favorites = [] } = privateData;
  
  return favorites.includes(listingId);
};

/**
 * Create a payload for updating the user's wishlist
 * 
 * @param {Object} currentUser - The current user object
 * @param {String} listingId - The ID of the listing to toggle
 * @returns {Object} - The payload for updating the user's profile
 */
export const createWishlistPayload = (currentUser, listingId) => {
  
  // Ensure the currentUser has the necessary structure
  if (!currentUser.attributes || !currentUser.attributes.profile) {
    console.error('User profile is not properly structured');
    // Return a payload with just this listing
    return {
      privateData: {
        favorites: [listingId],
      },
    };
  }
  
  const { attributes } = currentUser;
  const { profile } = attributes;
  const { privateData } = profile;
  const { favorites = [] } = privateData || {};
  
  const isFavorite = favorites.includes(listingId);
  
  let payload;
  
  if (!privateData || !privateData.favorites) {
    // No favorites yet, create a new array with this listing
    payload = {
      privateData: {
        favorites: [listingId],
      },
    };
  } else if (isFavorite) {
    // Already favorited, remove from favorites
    payload = {
      privateData: {
        favorites: favorites.filter(id => id !== listingId),
      },
    };
  } else {
    // Not favorited yet, add to favorites
    payload = {
      privateData: {
        favorites: [...favorites, listingId],
      },
    };
  }
  
  return payload;
};

/**
 * Handle toggling a listing in the wishlist
 * 
 * @param {Object} params - Parameters for handling the toggle
 * @param {Object} params.currentUser - The current user object
 * @param {Object} params.routes - The routes object
 * @param {Object} params.location - The current location object
 * @param {Object} params.history - The history object for navigation
 * @param {String} params.listingId - The ID of the listing to toggle
 * @param {Function} params.onUpdateWishlist - Function to call with the update payload
 * @returns {Function} - A function that handles the toggle
 */
export const handleToggleWishlist = params => () => {
  const { currentUser, routes, location, history, listingId, onUpdateWishlist } = params;
  
  // Only allow signed-in users to save favorites
  if (!currentUser) {
    // Save current URL to localStorage for redirect back after login
    if (typeof window !== 'undefined') {
      const currentUrl = `${location.pathname}${location.search}${location.hash}`;
      localStorage.setItem('wishlistRedirectUrl', currentUrl);
      
      // Save listing ID to localStorage for adding to wishlist after login
      const tempWishlist = JSON.parse(localStorage.getItem('tempWishlist') || '[]');
      if (!tempWishlist.includes(listingId)) {
        tempWishlist.push(listingId);
        localStorage.setItem('tempWishlist', JSON.stringify(tempWishlist));
      }
    }
    
    // Sign up and return back to the current page
    history.push(
      createResourceLocatorString('SignupPage', routes, {}, {}),
      { from: `${location.pathname}${location.search}${location.hash}` }
    );
    return;
  }
  
  const payload = createWishlistPayload(currentUser, listingId);
  onUpdateWishlist(payload);
};

/**
 * Get temporary wishlist from localStorage
 * 
 * @returns {Array} - Array of listing IDs in the temporary wishlist
 */
export const getTempWishlist = () => {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem('tempWishlist') || '[]');
  }
  return [];
};

/**
 * Clear temporary wishlist from localStorage
 */
export const clearTempWishlist = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('tempWishlist');
  }
};

/**
 * Get redirect URL from localStorage
 * 
 * @returns {String|null} - The URL to redirect to, or null if not set
 */
export const getWishlistRedirectUrl = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('wishlistRedirectUrl');
  }
  return null;
};

/**
 * Clear redirect URL from localStorage
 */
export const clearWishlistRedirectUrl = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('wishlistRedirectUrl');
  }
}; 