import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';

// ================ Action types ================ //

export const FETCH_WISHLIST_LISTINGS_REQUEST = 'app/WishlistPage/FETCH_WISHLIST_LISTINGS_REQUEST';
export const FETCH_WISHLIST_LISTINGS_SUCCESS = 'app/WishlistPage/FETCH_WISHLIST_LISTINGS_SUCCESS';
export const FETCH_WISHLIST_LISTINGS_ERROR = 'app/WishlistPage/FETCH_WISHLIST_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryParams: null,
  queryInProgress: false,
  queryWishlistError: null,
  currentPageResultIds: [],
  pagination: null,
};

const resultIds = data => {
  const ids = data?.data?.data?.map(l => l.id) || [];
  return ids;
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WISHLIST_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryWishlistError: null,
        currentPageResultIds: [],
      };
    case FETCH_WISHLIST_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_WISHLIST_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryWishlistError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryWishlistListingsRequest = queryParams => ({
  type: FETCH_WISHLIST_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryWishlistListingsSuccess = data => ({
  type: FETCH_WISHLIST_LISTINGS_SUCCESS,
  payload: { data },
});

export const queryWishlistListingsError = e => ({
  type: FETCH_WISHLIST_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryWishlistListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryWishlistListingsRequest(queryParams));
  
  const { currentUser } = getState().user;
  const { favorites = [] } = currentUser?.attributes.profile.privateData || {};
  
  // If there are no favorites, return empty results
  if (!favorites || favorites.length === 0) {
    const emptyResult = {
      data: {
        data: [],
        meta: {
          totalItems: 0,
          totalPages: 0,
          page: 1,
          perPage: queryParams.perPage || 24,
        },
      },
    };
    
    dispatch(queryWishlistListingsSuccess(emptyResult));
    // Return a resolved promise to maintain consistent return type
    return Promise.resolve(emptyResult);
  }
  
  const favoritesMaybe = favorites ? { ids: favorites } : {};
  const { perPage, ...rest } = queryParams;
  const params = { ...favoritesMaybe, ...rest, perPage };
  
  return sdk.listings
    .query(params)
    .then(response => {
      const { config } = getState();
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(queryWishlistListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryWishlistListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config?.layout?.listingImage || {};
  const aspectRatio = aspectHeight / aspectWidth;

  const queryParams = {
    ...params,
    perPage: 24,
    include: ['author', 'images'],
    'fields.listing': [
      'title',
      'geolocation',
      'price',
      'deleted',
      'state',
      'publicData.listingType',
      'publicData.transactionProcessAlias',
      'publicData.unitType',
      // These help rendering of 'purchase' listings,
      // when transitioning from search page to listing page
      'publicData.pickupEnabled',
      'publicData.shippingEnabled',
    ],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'limit.images': 1,
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
  
  return queryWishlistListings(queryParams);
}; 