import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import { currentUserShowSuccess } from './user.duck';

// ================ Action types ================ //

export const UPDATE_WISHLIST_REQUEST = 'app/wishlist/UPDATE_WISHLIST_REQUEST';
export const UPDATE_WISHLIST_SUCCESS = 'app/wishlist/UPDATE_WISHLIST_SUCCESS';
export const UPDATE_WISHLIST_ERROR = 'app/wishlist/UPDATE_WISHLIST_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateInProgress: false,
  updateWishlistError: null,
  lastUpdatedListingId: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_WISHLIST_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateWishlistError: null,
      };
    case UPDATE_WISHLIST_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
        lastUpdatedListingId: payload.listingId,
      };
    case UPDATE_WISHLIST_ERROR:
      return {
        ...state,
        updateInProgress: false,
        updateWishlistError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const updateWishlistRequest = () => ({
  type: UPDATE_WISHLIST_REQUEST,
});

export const updateWishlistSuccess = listingId => ({
  type: UPDATE_WISHLIST_SUCCESS,
  payload: { listingId },
});

export const updateWishlistError = error => ({
  type: UPDATE_WISHLIST_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

/**
 * Update the user's wishlist by adding or removing a listing
 * 
 * @param {Object} actionPayload - The payload for updating the user's profile
 * @param {String} listingId - The ID of the listing being toggled
 */
export const updateWishlist = (actionPayload, listingId) => {
  return (dispatch, getState, sdk) => {
    dispatch(updateWishlistRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateWishlistSuccess(listingId));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
        return response;
      })
      .catch(e => {
        dispatch(updateWishlistError(storableError(e)));
        throw e;
      });
  };
}; 